<template>
    <div>
        <div class="text-2 col-lg-12 p-0">
            <h4 class="text-center">Terms and Conditions</h4>
            <div>
                <p class="font-weight-500">END USER LICENCE AGREEMENT</p>
                <p>Last updated February 19, 2024</p>
                <p>VetConnect is licensed to You <b>('End-User')</b> by Fovea Tech Private Limited India <b>( 'Licensor')</b>, for use, for use only under the terms of this Licence
                    Agreement.</p>
                <p class="mt-2"> By downloading the Licensed Application from Apple's software distribution platform ('App Store') and Google's software distribution platform (Play
                    Store), and any
                    update thereto (as permitted by this Licence Agreement), You indicate that You agree to be bound by all of the terms and conditions of this Licence Agreement,
                    and
                    that You accept this Licence Agreement. The App Store and Play Store are referred to in this Licence Agreement as 'Services'.
                </p>
                <p>The parties of this Licence Agreement acknowledge that the Services are not a Party to this Licence Agreement and are not bound by any provisions or obligations
                    with
                    regard to the Licensed Application, such as warranty, liability, maintenance and support thereof. Fovea Tech private limited, not the Services, is solely
                    responsible for the Licensed Application and the content thereof.
                </p>
                <p>This Licence Agreement may not provide usage rules for the Licensed Application that are in conflict with the latest Apple Media Services Terms and Conditions
                    and
                    Google Play Terms of Service ( 'Usage Rules'). Fovea Tech private limited acknowledges that it had the opportunity to review the Usage Rules and this Licence
                    Agreement is not conflicting with them.</p>
                <p>VetConnect when purchased or downloaded through the Services, is licensed to You for use only under the terms of this Licence Agreement. The Licensor reserves
                    all
                    rights not expressly granted to You. VetConnect is to be used on devices that operate with Apple's operating systems (IOS and Mac OS) or Google's operating
                    system
                    (Android)</p>
                <p>If you don’t agree to the terms of this Licence Agreement do not click on “check box” button and do not download or use the Licensed Application</p>
            </div>
            <div class="mt-5">
                <p class="font-weight-500">1. THE APPLICATION</p>
                <p>VetConnect ( 'Licensed Application') is a piece of software created to to support veterinary professionals by providing information of the veterinary drugs and
                    their
                    dosages, as well as disease diagnosis and treatment options and customised for iOS and Android mobile devices <b>('Devices')</b>. The main use of this
                    application is to
                    streamline the workflow of veterinary professionals, allowing them to access crucial information quickly and efficiently, it also serves as a valuable resource
                    for
                    students studying veterinary medicine.</p>
                <p>
                    The Licensed Application is not tailored to comply with industry-specific regulations (Health Insurance Portability and Accountability Act (HIPAA), Federal
                    Information Security Management Act (FISMA), etc.), so if your interactions would be subjected to such laws, you may not use this Licensed Application. You may
                    not
                    use the Licensed Application in a way that would violate the Gramm-Leach-Billey Act (GLBA)</p>
            </div>
            <div>
                <p class="font-weight-500">2. SCOPE OF LICENCE</p>
                <p>2.1 You are given a non-transferable, non-exclusive, non-sublicensable licence to download, install and use the veterinary content of Licensed Application solely
                    for
                    your personnel use, non-commercial purposes on any Devices that You (End-User) own or control and as permitted by the terms of this agreement and Usage
                    Rules.</p>

                <p>2.2. This licence will also govern any updates of the Licensed Application provided by Licensor that replace, repair, and/or supplement the first Licensed
                    Application, unless a separate licence is provided for such update, in which case the terms of that new licence will govern</p>

                <p>2.3. You may not make the Licensed Application available to third parties (unless to the degree allowed by the Usage Rules, and with Fovea Tech private limited's
                    prior written consent), sell, rent, lend, lease or otherwise redistribute the Licensed Application</p>
                <p> 2.4 You may not reverse engineer, translate, disassemble, integrate, decompile, remove, modify, combine, create derivative works or updates of, adapt, or
                    attempt to
                    derive the source code of the Licensed Application, or any part thereof (except with Fovea Tech private limited's prior written consent).</p>

                <p>2.5 You may not copy (excluding when expressly authorised by this licence and the Usage Rules) or alter the Licensed Application or portions thereof. You may
                    create
                    and
                    store copies only on devices that You own or control for backup keeping under the terms of this licence, the Usage Rules, and any other terms and conditions
                    that
                    apply
                    to the device or software used. You may not remove any intellectual property notices. You acknowledge that no unauthorised third parties may gain access to
                    these
                    copies
                    at any time. If you sell your Devices to a third party, you must remove the Licensed Application from the Devices before doing so.</p>

                <p> 2.6 Violations of the obligations mentioned above, as well as the attempt of such infringement, may be subject to prosecution and damages.</p>

                <p> 2.7 Licensor reserves the right to modify the terms and conditions of licensing</p>

                <p>2.8 Nothing in this licence should be interpreted to restrict third-party terms. When using the Licensed Application, You must ensure that You comply with
                    applicable
                    third-party terms and conditions</p>

                <p>2.9 If we found any misuse or modification of our content outside Licensed Application then we may permanently remove your account without any communication or
                    notice.
                    You must not republish materials from Licensed Application to other platforms. You must not reproduce, duplicate, copy or redistribute materials from Licensed
                    Application</p>
            </div>
            <div>
                <p class="font-weight-500">3. TECHNICAL REQUIREMENTS</p>
                <p>3.1 You acknowledge that it is Your responsibility to confirm and determine that the app end-user device on which You intend to use the Licensed Application
                    satisfies
                    the technical specifications mentioned above.</p>

                <p>3.2 Licensor reserves the right to modify the technical specifications as it sees appropriate at any time</p>
            </div>
            <div>

                <p class="font-weight-500">4. MAINTENANCE AND SUPPORT</p>
                <p>4.1 The Licensor is solely responsible for providing any maintenance and support services for this Licensed Application. You can reach the Licensor at the email
                    address
                    listed in the App Store or Play Store Overview for this Licensed Application.</p>

                <p>4.2 Fovea Tech private limited and the End-User acknowledge that the Services have no obligation whatsoever to furnish any maintenance and support services with
                    respect
                    to the Licensed Application</p>
            </div>

            <div>
                <p class="font-weight-500"> 5. USE OF DATA</p>
                <p>You acknowledge that Licensor will be able to access and adjust Your downloaded Licensed Application content and Your personal information, and that Licensor's
                    use
                    of
                    such material and information is subject to Your legal agreements with Licensor and Licensor's privacy policy, which can be accessed by by clicking on the left
                    side
                    icon.</p>

                <p> You acknowledge that the Licensor may periodically collect and use technical data and related information about your device, system, and application software,
                    and
                    peripherals, offer product support, facilitate the software updates, and for purposes of providing other services to you (if any) related to the Licensed
                    Application.
                    Licensor may also use this information to improve its products or to provide services or technologies to you, as long as it is in a form that does not
                    personally
                    identify you.</p>
            </div>
            <div>
                <p class="font-weight-500">6. USER-GENERATED CONTRIBUTIONS</p>
                <p> The Licensed Application may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and other functionality, and may provide
                    you
                    with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or in the Licensed
                    Application,
                    including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material
                    (collectively,
                    Contributions"). As such, any Contributions you transmit may be treated as non-confidential and non-proprietary When you create or make available any
                    Contributions,
                    you
                    thereby represent and warrant that:</p>

                <p> 1. The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions do not and will
                    not
                    infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third party.</p>

                <p> 2. You are the creator and owner of or have the necessary licences, rights, consents, releases, and permissions to use and to authorise us, the Licensed
                    Application,
                    and other users of the Licensed Application to use your Contributions in any manner contemplated by the Licensed Application and this Licence Agreement.</p>

                <p> 3. You have the written consent, release, and/or permission of each and every identifiable individual person in your Contributions to use the name or likeness
                    or
                    each
                    and every such identifiable individual person to enable inclusion and use of your Contributions in any manner contemplated by the Licensed Application and this
                    Licence
                    Agreement.</p>

                <p>4. Your Contributions are not false, inaccurate, or misleading.</p>

                <p>5. Your Contributions are not unsolicited or unauthorised advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms
                    of
                    solicitation</p>

                <p>6. Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libellous slanderous or otherwise objectionable (as determined by us)</p>

                <p>7. Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone</p>

                <p> 8. Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person and to promote violence against a specific person
                    or
                    class
                    of people</p>

                <p>9. Your Contributions do not violate any applicable law, regulation, or rule.</p>

                <p>10. Your Contributions do not violate the privacy or publicity rights of any third party</p>

                <p> 11. Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to protect the health or well-being of minors</p>

                <p>12. Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap.</p>

                <p>13. Your Contributions do not otherwise violate, or link to material that violates, any provision of this Licence Agreement, or any applicable law or
                    regulation</p>

                <p> Any use of the Licensed Application in violation of the foregoing violates this Licence Agreement and may result in, among other things, termination or
                    suspension
                    of
                    your rights to use the Licensed Application</p>
            </div>
            <div>
                <p class="font-weight-500">7. CONTRIBUTION LICENCE</p>
                <p> By sharing your Contributions to any part of the Licensed Application or making Contributions accessible to the Licensed Application by linking your account
                    from
                    the
                    Licensed Application to any of your social networking accounts, you automatically grant, and you represent and warrant that you have the right to grant, to us
                    an
                    unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and licence to host, use copy,
                    reproduce,
                    disclose, sell, resell, publish, broad cast, retitle, archive, store, cache, publicly display, reformat, translate, transmit, excerpt (in whole or in part), and
                    distribute such Contributions (including, without limitation, your image and voice) for any purpose, commercial advertising, or otherwise, and to prepare
                    derivative
                    works of, or incorporate in other works, such as Contributions, and grant and authorise sublicences of the foregoing. The use and distribution may occur in any
                    media
                    formats and through any media channels</p>

                <p>This licence will apply to any form, media, or technology now known or hereafter developed, and includes our use of your name, company name, and franchise name
                    as
                    applicable and any of the trademarks, service marks, trade names, logos, and personal and commercial images you provide. You waive all moral rights in your
                    Contributions, and you warrant that moral rights have not otherwise been asserted in your Contributions</p>

                <p> We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and any intellectual property rights or other
                    proprietary
                    rights associated with your Contributions. We are not liable for any statements or representations in your Contributions provided by you in any area in the
                    Licensed
                    Application. You are solely responsible for your Contributions to the Licensed Application and you expressly agree to exonerate us from any and all
                    responsibility
                    and
                    to refrain from any legal action against us regarding your Contributions</p>

                <p> We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change any Contributions, (2) to recategorize any Contributions to
                    place
                    them
                    in more appropriate locations in the Licensed Application, and (3) to prescreen or delete any Contributions at any time and for any reason, without notice. We
                    have
                    no
                    obligation to monitor your Contributions.</p>
            </div>
            <div>
                <p class="font-weight-500">8. VETERINARY CONTENT IN APPLICATION</p>
                <p> The veterinary content provided within the Licensed Application is for informational and educational purposes only and is not intended to be a complete
                    substitute
                    for
                    professional medical advice, diagnosis, or treatment. The diagnosis results provided by the application should be considered indicative of the likelihood of a
                    disease
                    and should not be solely relied upon for conclusive assessments. You should acknowledge that the veterinary content may not be complete, accurate, or
                    up-to-date.</p>
            </div>
            <div>
                <p class="font-weight-500"> 9. LIABILITY</p>
                <p>9.1. Licensor takes no accountability or responsibility for any damages caused due to a breach of duties according to Section 2 of this Licence Agreement. You
                    are
                    aware
                    that in case of alterations or manipulations of the Licensed Application, You will not have access to the Licensed Application</p>

                <p>9.2 You agrees that Licensor shall not be liable for any errors, inaccuracies, or omissions in the veterinary content provided within the Licensed Application.
                    Licensor
                    shall not be responsible for any consequences resulting from Yours’s reliance on or use of the veterinary content, including its usage on animals.</p>

                <p>9.3 The Licensor’s liability for any server issues or downtime shall be limited to the extent permitted by applicable law.</p>

                <p> 9.4 In no event shall the Licensor be liable for any loss or damage arising out of or in connection with server issues or downtime, including but not limited to
                    loss of
                    data, loss of business opportunity, or any indirect, incidental, special, or consequential damages.</p>
            </div>
            <div>
                <p class="font-weight-500">10. WARRANTY</p>
                <p> 10.1. Licensor warrants that the Licensed Application is free of spyware, trojan horses, viruses, or any other malware at the time of Your download. Licensor
                    warrants
                    that the Licensed Application works as described in the user documentation.</p>

                <p>10.2 No warranty is provided for the Licensed Application that is not executable on the device, that has been unauthorisedly modified, handled inappropriately or
                    culpably, combined or installed with inappropriate hardware or software, used with inappropriate accessories, regardless if by Yourself or by third parties, or
                    if
                    there
                    are any other reasons outside of Fovea Tech private limited's sphere of influence that affect the executability of the Licensed Application</p>

                <p> 10.3 You are required to inspect the Licensed Application immediately after installing it and notify Fovea Tech private limited about issues discovered without
                    delay by
                    email provided in Contact Information. The defect report will be taken into consideration and further investigated if it has been emailed within a period of
                    thirty
                    (30)
                    days after discovery</p>

                <p>10.4 If we confirm that the Licensed Application is defective, Fovea Tech private limited reserves a choice to remedy the situation either by means of solving
                    the
                    defect or substitute delivery</p>

                <p>10.5 To the maximum extent permitted by applicable law, the Services Store Operator will have no other warranty obligation whatsoever with respect to the
                    Licensed
                    Application, and any other losses, claims, damages, liabilities, expenses, and costs attributable to any negligence to adhere to any warranty.</p>

                <p>9.6 if the user is an entrepreneur, any claim based on faults expires after a statutory period of limitation amounting to twelve (12) months after the Licensed
                    Application was made available to the user. The statutory periods of limitation given by law apply for users who are consumers</p>

                <p>9.7 the veterinary content provided within the application is provided "as is" without any warranty, express or implied. Fovea Tech private limited disclaims all
                    warranties, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
            </div>
            <div>
                <p class="font-weight-500"> 11. PRODUCT CLAIMS</p>
                <p> Fovea Tech private limited and the End-User acknowledge that Fovea Tech private limited, and not the Services, is responsible for addressing any claims of the
                    End-
                    User
                    or any third party relating to the Licensed Application or the End-User's possession and or use of that Licensed Application, including, but not limited to:</p>

                <p> (1) product liability claims;</p>

                <p>(i) any claim that the Licensed Application fails to conform to any applicable legal or regulatory requirement, and</p>

                <p>(i) claims arising under consumer protection, privacy, or similar legislation, including in connection with Your Licensed Application's use of the Healthkit and
                    HomeKit</p>
            </div>
            <div>
                <p class="font-weight-500">12. SERVER ISSUES AND RECTIFICATION TIME</p>
                <p> Fovea Tech private limited strives to maintain the availability and performance of its servers to ensure uninterrupted access to the services provided by the
                    Licensed
                    Application. However, you acknowledge that from time to time, the Services may experience downtime, server issues, or interruptions due to various factors
                    including
                    maintenance, technical problems, or unforeseen circumstances.</p>

                <p>In the event of server issues or downtime affecting the availability of the Services, the Licensor will make reasonable efforts to rectify the issue and restore
                    the
                    Services to normal operation in a timely manner.</p>

                <p>The Licensor shall not be liable for any damages, losses, or inconvenience caused due to server issues or downtime, including but not limited to loss of data,
                    loss
                    of
                    business opportunity, or any indirect, incidental, special, or consequential damages.</p>
            </div>
            <div>
                <p class="font-weight-500">13. LEGAL COMPLIANCE</p>
                <p> You represent and warrant that You are not located in a country that is subject to a US Government embargo, or that has been designated by the US Government as
                    a
                    terrorist supporting country, and that You are not listed on any US Government list of prohibited or restricted parties.</p>
            </div>
            <div>
                <p class="font-weight-500">14. CONTACT INFORMATION</p>
                <h6> 14. CONTACT INFORMATION</h6>

                <p> For general inquiries, complaints, questions or claims concerning the Licensed Application, please contact:</p>

                <p>Fovea Tech Private Limited India</p>

                <p>foveatechvet@gmail.com</p>
            </div>
            <div>
                <p class="font-weight-500">15. TERMINATION & MODIFICATION</p>
                <p>The licence is valid until terminated by Fovea Tech private limited or by You. Your rights under this licence will terminate automatically and without notice
                    from
                    Fovea
                    Tech private limited if You fail to adhere to any term(s) of this licence Upon Licence termination, You shall stop all use of the Licensed Application, and
                    destroy
                    all
                    copies, full or partial, of the Licensed Application. You may also terminate this licence by deleting the Licensed Application and all copies thereof from Your
                    mobile
                    device or from desktop.</p>

                <p>Licensor reserve the right to modify suspend or discontinue, temporarily or permanently, the Licensed Application or any services to which it connects, with or
                    without
                    notice and without liability to you.</p>
            </div>
            <div>
                <p class="font-weight-500"> 16. PAYMENTS</p>
                <p>We offer paid features in app, any payment done by you for a paid features will be non-refundable in any case.</p>

                <p> Monthly plan validity is 30 days</p>

                <p> 3-month plan validity is 90 days</p>

                <p>Half yearly plan validity is 180 days</p>

                <p>Yearly plan validity is 365 days</p>
            </div>
            <div>
                <p class="font-weight-500">17. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY</p>
                <p>Fovea Tech private limited represents and warrants that Fovea Tech private limited will comply with applicable third-party terms of agreement when using Licensed
                    Application.</p>

                <p>In Accordance with Section & of the Instructions for Minimum Terms of Developer's End-User Licence Agreement, both Apple and Google and their subsidiaries shall
                    be
                    third-party beneficiaries of this End User Licence Agreement and-upon Your acceptance of the terms and conditions of this Licence Agreement, both Apple and
                    Google
                    will
                    have the right (and will be deemed to have accepted the right) to enforce this End User Licence Agreement against You as a third-party beneficiary thereof.</p>
            </div>
            <div>
                <p class="font-weight-500">18. INDEMNIFICATION</p>
                <p> You agree to indemnify, defend, and hold harmless Fovea Tech private limited, its affiliates, officers, directors, employees, and agents from and against any
                    claims,
                    liabilities, damages, losses, costs, or expenses arising out of or related to End User's use of the veterinary content within the Licensed Application.</p>
            </div>
            <div>
                <p class="font-weight-500"> 19. INTELLECTUAL PROPERTY RIGHTS</p>
                <p> Fovea Tech private limited and the End-User acknowledge that, in the event of any third-party claim that the Licensed Application or the End-User's possession
                    and
                    use
                    of that Licensed Application infringes on the third party's intellectual property rights, Fovea Tech private limited, and not the Services, will be solely
                    responsible
                    for the investigation, defence, settlement, and discharge or any such intellectual property infringement claims</p>

                <p> The App and its contents, including but not limited to text, graphics, images, videos, and software, are owned by Company and protected by intellectual property
                    laws.</p>
            </div>
            <div>
                <p class="font-weight-500">20. APPLICABLE LAW</p>
                <p> This Licence Agreement is governed by the laws of India excluding its conflicts of law rules</p>
            </div>
            <div>
                <p class="font-weight-500"> 21. SEVERABILITY</p>
                <p>21.1 if any of the terms of this agreement should be or become invalid, the validity of the remaining provisions shall not be affected. Invalid terms will be
                    replaced
                    by valid ones formulated in a way that will achieve the primary purpose</p>

                <p> 21.2 Collateral agreements, changes and amendments are only valid if laid down in writing. The preceding clause can only be waived in writing.</p>
            </div>
            <div>
                <p class="font-weight-500"> 22. UPDATES TO TERMS & CONDITION</p>
                <p>Fovea Tech private limited reserves the right to update or modify these terms and conditions at any time. You will be provided at least 5 days’ notice prior to
                    any
                    changes to the terms via the App or by other reasonable means. Your continued use of the App after such modifications constitutes acceptance of the updated
                    terms.</p>

                <p>By using the App, you acknowledge that you have read, understood, and agree to be bound by the terms and conditions of this Agreement.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Termsandconditions'
};
</script>

<style scoped>

</style>
